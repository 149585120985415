import React from 'react';

import { Section } from 'react-bulma-components';

export const StyledSection = ({id, style='light', children}) => (
	<Section
		id={id}
		className={`${id} section-${style}`}>
		{children}
	</Section>
);
