import React from 'react';

import { Hero, Navbar } from 'react-bulma-components';

const logo = require("../images/logo_wide.png")

export const NavBar = () => (
	<Hero.Head>
		<Navbar>
			<Navbar.Brand>
				<Navbar.Item renderAs="a">
        			<a className="logo" href="/">
						<img
							src={logo}
							alt="Photo Roboto | Instantly connect your camera to the Cloud" />
					</a>
				</Navbar.Item>
			</Navbar.Brand>
		</Navbar>
	</Hero.Head>
);
