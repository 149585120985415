import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

import "../style/scrollToTop.scss"

export class ScrollToTop extends Component {
	constructor(props) {
	  super(props);
  
	  this.state = {
		  intervalId: 0,
		  scrollStepInPx: props.scrollStepInPx ?? "50",
		  delayInMs: props.delayInMs ?? "16.66"
	  };
	}

	scrollStep = () => {
	  if (window.pageYOffset === 0) {
		  clearInterval(this.state.intervalId);
	  } else {
		window.scroll(0, window.pageYOffset - this.state.scrollStepInPx);
	  }
	}

	scrollToTop = () => {
	  this.setState({
		  intervalId: setInterval(this.scrollStep.bind(this), this.state.delayInMs)
	  });
	}

	render() {
		return (
			<button
				id="scrollToTop"
				title='Back to top'
				className='scrollToTop' 
				onClick={this.scrollToTop}>
				<FontAwesomeIcon icon={faArrowUp} />
			</button>
		);
	}
};
