import React from 'react'
import { Button, Columns, Heading } from 'react-bulma-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAndroid, faApple, faLinkedinIn, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

import "../style/footer.scss"

const logo = require("../images/logo_wide.png")

export const Footer = () => (
  <footer>
    <div className="footerContainer">
      <Columns.Column
        id="download"
        style={{textAlign: "center"}}>
        <a className="logo" href="/">
          <img
            src={logo}
            alt="Photo Roboto | Instantly connect your camera to the Cloud" />
        </a>
        <Heading size={5}>
          Download
        </Heading>
        <Heading size={6} subtitle>
          Photo Roboto Mobile App
        </Heading>
        <Columns>
          <Columns.Column>
            <a href="">
              <Button
                rounded
                className="footerDownloadButton">
                  <FontAwesomeIcon icon={faApple} />
                  &nbsp;&nbsp;Download for Apple iOS
              </Button>
            </a>
          </Columns.Column>
          <Columns.Column>
            <a href="https://play.google.com/store/apps/details?id=com.photoroboto">
              <Button
                rounded
                className="footerDownloadButton">
                  <FontAwesomeIcon icon={faAndroid} />
                  &nbsp;&nbsp;Download for Android
              </Button>
            </a>
          </Columns.Column>
        </Columns>
        <hr />
        <Columns>
          <Columns.Column>
            <p>
              <strong>Give me a ring</strong>
            </p>
            <a className="subheading" href="tel:204-285-9765">
              (204) 285-9765
            </a>
          </Columns.Column>
          <Columns.Column>
            <p>
              <strong>Email Me</strong>
            </p>
            <a className="subheading" href="mailto:contact@plishkaconsulting.com">
              contact@plishkaconsulting.com
            </a>
          </Columns.Column>
        </Columns>
        <br />
        <div>
          <Button
            size="medium"
            className="footerSocialButton">
            <a href="https://www.linkedin.com/company/plishka-consulting">
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size="lg" />
            </a>
          </Button>
          <Button
            size="medium"
            className="footerSocialButton">
            <a href="https://www.facebook.com/plishkaconsulting">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="lg" />
            </a>
          </Button>
          <Button
            size="medium"
            className="footerSocialButton">
            <a href="https://www.instagram.com/plishkaconsulting/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="lg" />
            </a>
          </Button>
        </div>
        <br />
        <p>
          Built with
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="brain"
            className="svg-inline--fa fa-brain fa-w-18"
            width="11.243"
            height="10.378"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="#ee5a9c"
              d="M208 0c-29.9 0-54.7 20.5-61.8 48.2-.8 0-1.4-.2-2.2-.2-35.3 0-64 28.7-64 64 0 4.8.6 9.5 1.7 14C52.5 138 32 166.6 32 200c0 12.6 3.2 24.3 8.3 34.9C16.3 248.7 0 274.3 0 304c0 33.3 20.4 61.9 49.4 73.9-.9 4.6-1.4 9.3-1.4 14.1 0 39.8 32.2 72 72 72 4.1 0 8.1-.5 12-1.2 9.6 28.5 36.2 49.2 68 49.2 39.8 0 72-32.2 72-72V64c0-35.3-28.7-64-64-64zm368 304c0-29.7-16.3-55.3-40.3-69.1 5.2-10.6 8.3-22.3 8.3-34.9 0-33.4-20.5-62-49.7-74 1-4.5 1.7-9.2 1.7-14 0-35.3-28.7-64-64-64-.8 0-1.5.2-2.2.2C422.7 20.5 397.9 0 368 0c-35.3 0-64 28.6-64 64v376c0 39.8 32.2 72 72 72 31.8 0 58.4-20.7 68-49.2 3.9.7 7.9 1.2 12 1.2 39.8 0 72-32.2 72-72 0-4.8-.5-9.5-1.4-14.1 29-12 49.4-40.6 49.4-73.9z"
            />
          </svg>
          by{' '}
          <a href="https://plishkaconsulting.com"><b>Plishka Consulting</b></a>
        </p>
        <hr />
        <p>© 2020 Plishka Consulting. All rights reserved.</p>
        <p><a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-and-conditions">Terms & Conditions</a></p>
      </Columns.Column>
    </div>
  </footer>
)
