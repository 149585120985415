import React, { Fragment } from "react"

import { Footer } from "../components/footer"
import Helmet from "../components/helmet"
import { ScrollToTop } from '../components/scrollToTop'

import "../style/hero.scss"
import "../style/main.scss";
import '../style/preloader.scss';
import '../style/style.scss';

const Layout = ({ children }) => (
	<Fragment>
		<Helmet />
		{children}
        <Footer />
        <ScrollToTop />
	</Fragment>
);

export default Layout
